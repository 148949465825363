<template>
  <div class="appealDetail tw-overflow-y-auto tw-h-full">
    <Header :title="title" theme="light" step="/" />
    <div class="container " :class="imgList == '' ? 'heigth' : ''">
      <div class="mb_4px">
        申诉人 : <span>{{ getItem.generationMethod == 0 ? getItem.userName : getItem.traderName }}</span>
      </div>
      <div class="mb_4px">
        被申诉人 : <span>{{ getItem.generationMethod == 0 ? getItem.traderName : getItem.userName }}</span>
      </div>
      <div class="mb_4px">
        订单编号 : <span>{{ getItem.orderNo }}</span>
      </div>
      <div class="mb_4px">
        订单金额 : <span>{{ getItem.orderAmount }}</span>
      </div>
      <div class="mb_4px">
        订单类型 : <span style="color:#FF6C18;">{{ generationMethodDesc }}</span>
      </div>
      <div class="mb_4px">
        申诉类型 : <span>{{ getItem.appealTypeDesc }}</span>
      </div>
      <div class="mb_4px">
        处理进度 : <span>{{ getItem.statusDesc }}</span>
      </div>
      <div class="mb_4px">
        支付方式 : <span>{{ getItem.channelName }}</span>
      </div>
      <div class="mb_4px">
        发起时间 : <span>{{ getItem.createdAt }}</span>
      </div>
      <div class="mb_4px word_wrap">
        申诉原因 : <span style="color:#FF6C18;">{{ getItem.appealDesc }}</span>
      </div>
      <div class="mb_4px">
        处理结果 : <span style="color: red;">{{ getItem.statusDesc }}</span>
      </div>
    </div>
    <div class="container_img" v-if="imgList != ''">
      <div>我的截图:</div>
      <div>
        <img class=" tw-mx-auto tw-m-1 tw-object-contain tw-w-64 tw-h-64" v-for="item in imgList" :key="item.id" :src="item" alt="" @click="openImageDialog(item)" />
      </div>
    </div>
    <div class="tw-mt-4 tw-grid  tw-items-center tw-justify-between" :class="getItem.generationMethod == 0 ? 'tw-grid-cols-2' : 'tw-grid-cols-1'">
      <div class="tw-flex tw-items-center tw-justify-center tw-gap-8">
        <div
          @click="
            $router.push({
              path: `/message/${encodeURIComponent(logData.traderName)}/C2Ctrader_im_${logData.traderId}/trader_im_${logData.traderId}`,
            })
          "
        >
          <img src="../../assets/images/public/ic_payment_information.png" alt="" />
        </div>
        <div @click="payPhone(logData.userMobile)">
          <img src="../../assets/images/public/ic_payment_phone.png" alt="" />
        </div>
      </div>
      <div class=" tw-text-white tw-bg-[#ff6c18] tw-h-full tw-items-center tw-flex " v-if="getItem.generationMethod == 0" @click="checkAppealBox">
        <p class="tw-mx-auto">
          我要撤诉
        </p>
      </div>
    </div>
    <!-- <div class="footer">
      <div
        @click="
          $router.push({
            path: `/message/${encodeURIComponent(logData.traderName)}/C2Ctrader_im_${logData.traderId}/trader_im_${logData.traderId}`,
          })
        "
      >
        <img src="../../assets/images/public/ic_payment_information.png" alt="" />
      </div>
      <div @click="payPhone(logData.userMobile)">
        <img src="../../assets/images/public/ic_payment_phone.png" alt="" />
      </div>
      <div class="abnormalBox" v-if="getItem.generationMethod == 0">
        <div class="abnormalBtn" @click="checkAppealBox">
          <div>我要撤诉</div>
        </div>
      </div>
    </div> -->
    <div class="checkPayMoneyPopup" v-if="checkPopup == true">
      <div class="container__main">
        <div class="title">提示</div>
        <div class="content">
          撤销申诉订单自动完成,请确认您已收到款
        </div>
        <div class="box__btn">
          <div @click="checkPopup = false">取消</div>
          <div @click="cancelAppeal()">撤销申诉</div>
        </div>
      </div>
    </div>
    <el-dialog class="my_dialog" :visible.sync="visible" :width="dialogWidth" :show-close="false">
      <img :src="src" />
    </el-dialog>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import { mapActions, mapState } from "vuex";
import { Message } from "element-ui";

export default {
  name: "appealDetail",
  components: {
    Header,
  },
  data() {
    return {
      title: "申诉详情",
      checkPopup: false,
      dialogWidth: "100%",
      visible: false,
      src: "",
    };
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.setDialogWidth();
      })();
    };
  },
  computed: {
    ...mapState("appeals", ["appealsData"]),
    ...mapState("orders", ["logData"]),
    generationMethodDesc() {
      let status = "0";
      if (this.appealsData.length > 0) {
        status = this.appealsData[0].generationMethodDesc;
      }
      return status;
    },
    getItem() {
      let data = {
        userName: "",
        traderName: "",
        orderNo: "",
        orderAmount: "",
        appealTypeDesc: "",
        statusDesc: "",
        channelName: "",
        createdAt: "",
        appealDesc: "",
        generationMethod: 0,
      };
      if (this.appealsData.length > 0) {
        data.userName = this.appealsData[0].userName;
        data.traderName = this.appealsData[0].traderName;
        data.orderNo = this.appealsData[0].orderNo;
        data.orderAmount = this.appealsData[0].orderAmount;
        data.appealTypeDesc = this.appealsData[0].appealTypeDesc;
        data.statusDesc = this.appealsData[0].statusDesc;
        data.channelName = this.appealsData[0].channelName;
        data.createdAt = this.appealsData[0].createdAt;
        data.appealDesc = this.appealsData[0].appealDesc;
        data.generationMethod = this.appealsData[0].generationMethod;
      }
      return data;
    },
    imgList() {
      let imgList = [""];
      if (this.appealsData.length > 0) {
        if (this.appealsData[0].screenshot != "") {
          imgList = this.appealsData[0].screenshot.split(",");
        } else {
          imgList = [];
        }
      }
      return imgList;
    },
  },
  async created() {
    await this.init();
  },
  methods: {
    ...mapActions("appeals", ["getAppeals", "cancelAppeals"]),
    openImageDialog(src) {
      this.visible = true;
      this.src = src;
    },
    setDialogWidth() {
      let windowSize = document.body.clientWidth;
      const defaultWidth = 450; // 預設寬度
      if (windowSize < defaultWidth) {
        this.dialogWidth = "100%";
      } else {
        this.dialogWidth = defaultWidth + "px";
      }
    },
    async init() {
      try {
        let data = {
          orderNo: this.$route.query.orderNo,
          status: 0,
        };
        await this.getAppeals(data);
      } catch (error) {
        console.log(error);
      }
    },
    payPhone(num) {
      location.href = `tel:${num}`;
    },

    checkAppealBox() {
      this.checkPopup = true;
    },

    cancelAppeal() {
      const self = this;
      self.cancelAppeals(self.appealsData[0].appealNo).then((res) => {
        if (res.code === "ok") {
          Message({
            message: "撤销申诉成功",
            iconClass: "x",
            center: true,
            customClass: "sucess_message",
          });
          this.$router.push("/");
        } else {
          Message({
            message: res.msg,
            iconClass: "x",
            center: true,
            customClass: "sucess_message",
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.word_wrap {
  width: 75%;
  text-align: left;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.checkPayMoneyPopup {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 900;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  .container__main {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: aliceblue;
    width: 80%;
    height: fit-content;
    padding: 2rem 1rem;
    border-radius: 15px;
    // max-width: 414px;
    @media (min-width: 480px) {
      max-width: unset;
    }
    .title {
      font-weight: bold;
      color: var(--orange);
      font-size: 1.2rem;
      margin-bottom: 1.5rem;
    }

    .content {
      text-align: left;
      margin-bottom: 1.5rem;
    }

    .box__btn {
      display: flex;
      justify-content: space-around;

      div {
        width: 40%;
        padding: 0.8rem 0rem;
        color: aliceblue;
        border-radius: 20px;

        &:nth-child(1) {
          background-color: var(--light-black);
        }

        &:nth-child(2) {
          background-color: var(--orange);
        }
      }
    }
  }
}

.appealDetail {
  width: 100%;
  overflow-y: scroll;
  height: 192vw;
  background-color: rgb(219, 219, 219);

  .container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: #fff;
    align-content: flex-start;

    & > div {
      width: 100%;
      text-align: left;
      font-size: 14px;
      margin-bottom: 10px;
    }
  }

  .container_img {
    width: 100%;
    text-align: left;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-top: 1rem;
    background-color: #fff;
    // min-height: 73vw;

    div {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;

      img {
        margin-top: 1rem;
        width: 40%;
      }
    }
  }
}

.footer {
  // position: fixed;
  // bottom: 0;
  // left: 0;
  // width: 100%;
  // display: flex;
  // justify-content: flex-start;
  // align-items: center;

  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  // max-width: 414px;
  div:nth-child(1),
  div:nth-child(2) {
    width: 10%;
    margin-left: 1.5rem;
    font-size: 0;

    img {
      width: 100%;
    }
  }

  .abnormalBox {
    width: 80%;
    display: flex;
    justify-content: flex-end;

    .abnormalBtn {
      font-size: 16px;
      background-color: #ff6c18;
      width: 80%;
      height: 49px;
      display: flex;
      justify-content: center;
      align-items: center;

      div {
        font-size: 16px;
        color: #fff;
        width: 100%;
        margin: 0;
      }
    }
  }
}

.heigth {
  height: 181vw;
}
</style>

<style>
.el-dialog__wrapper {
  overflow: hidden !important;
}
.el-dialog {
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 3px rgb(0 0 0 / 0%) !important;
  margin-top: 0vh !important;
  height: 100vh !important;
  pointer-events: none;
}
.el-dialog__header {
  padding: 0 !important;
}
.el-dialog__body {
  display: block;
  padding: 0;
  overflow: hidden;
  width: 100% !important;
}
.el-dialog__body img {
  width: 100%;
  object-fit: contain;
}
.el-dialog__headerbtn .el-dialog__close {
  display: none;
}
.el-dialog {
  background: transparent;
}
</style>
